import * as React from "react"
import { Link } from "gatsby"
import { useEffect } from 'react';
import Layout from "../../templates/layout-standard"
import Seo from "../../components/seo"
import SideBar from '../../components/sidebar';
import LearnMore from '../../components/learnmore';
import SpeakWithAdvisor from '../../components/ui/SpeakWithAdvisor';

const MBFAQ = ({ location }) => {

	useEffect(() => {
		document.querySelectorAll(".accordiontrigger").forEach(trigger => {
			let accordionContainer = trigger.nextElementSibling;
		
			trigger.addEventListener('click', event => {
				trigger.classList.toggle("open");
				if (trigger.classList.contains('open')) {
					trigger.setAttribute("aria-hidden", "false");
				} else {
					trigger.setAttribute("aria-hidden", "true");
				}
			
				if (!accordionContainer.classList.contains('open')) {
					accordionContainer.classList.add('open');
					accordionContainer.style.height = 'auto';
				
					let height = accordionContainer.clientHeight + 'px';
				
					accordionContainer.style.height = '0px';
				
					setTimeout(function () {
						accordionContainer.style.height = height;
					}, 0);
				} else {
					accordionContainer.style.height = '0px';		
					accordionContainer.addEventListener('transitionend', function () {
						accordionContainer.classList.remove('open');
					}, {
						once: true
					});
				}
		
			});
		});
	});


	return (
		<Layout location={location} heading1="FAQs" heading2="Answers to questions you may have.">
			<Seo title="FAQ | Medicare Basics " meta={[{description: 'Get answers to questions you may have about Medicare.'}]} bodyclass="medicare-basics section1 subpage" />
				
			<div class="constrained">
				<div class="content-constrained">
					<div class="flexwrap_subpage">

						<main>
							<h3>Questions? UVM Health Advantage can help.</h3>
							
							<h4>Terms</h4>
							<div class="accordion">
								<div class="accordion-item">
									<button type="button" id="accordion-trigger-01" class="accordiontrigger" aria-controls="accordion-content-01" aria-hidden="true">
										What is Medicare?
									</button>
									<div id="accordion-content-01" class="accordion-content" aria-labelledby="accordion-trigger-01">
										<div class="contentwrap">
											<p>Medicare is the federal health insurance program for people who are 65 or older, certain younger people with disabilities, and people with End-Stage Renal Disease (permanent kidney failure requiring dialysis or a transplant, sometimes called ESRD).</p>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<button type="button" id="accordion-trigger-02" class="accordiontrigger" aria-controls="accordion-content-02" aria-hidden="true">
										What is Original Medicare?
									</button>
									<div id="accordion-content-02" class="accordion-content" aria-labelledby="accordion-trigger-02">
										<div class="contentwrap">
											<p>Original Medicare refers to Medicare Part A and Part B. It is referred to as &ldquo;original&rdquo; Medicare because these were the only two parts of Medicare when the program started.</p>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<button type="button" id="accordion-trigger-03" class="accordiontrigger" aria-controls="accordion-content-03" aria-hidden="true">
										What is a Medicare Advantage plan?
									</button>
									<div id="accordion-content-03" class="accordion-content" aria-labelledby="accordion-trigger-03">
										<div class="contentwrap">
											<p>A Medicare Advantage health plan (or Part C plan) is an alternative to Medicare Parts A and B. These plans typically cover what Part A and Part B cover, as well as other benefits, such as prescription drugs, worldwide emergency room coverage, and more. <Link to="/plans/uvm-health-advantage/">See our plans</Link>.</p>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<button type="button" id="accordion-trigger-04" class="accordiontrigger" aria-controls="accordion-content-04" aria-hidden="true">
										What is Part D?
									</button>
									<div id="accordion-content-04" class="accordion-content" aria-labelledby="accordion-trigger-04">
										<div class="contentwrap">
											<p>Part D includes prescription drugs that are federally approved for Medicare beneficiaries. Part D drug coverage is offered by private companies. Plans can vary in cost and specific drugs covered. You may be eligible for help paying your Part D costs.</p>
										</div>
									</div>
								</div>
							</div>
							
							<h4>Enrolling</h4>
							<div class="accordion">
								<div class="accordion-item">
									<button type="button" id="accordion-trigger-05" class="accordiontrigger" aria-controls="accordion-content-05" aria-hidden="true">
										When do I need to enroll in Medicare?
									</button>
									<div id="accordion-content-05" class="accordion-content" aria-labelledby="accordion-trigger-05">
										<div class="contentwrap">
											<p>You can sign up for Medicare Part A and Part B anytime starting three months before your 65th birthday, up until three months after your 65th birthday. Once you&rsquo;ve signed up for Medicare Part A and Part B, you can enroll in a UVM Health Advantage Medicare Advantage plan with Part D prescription drug coverage.</p>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<button type="button" id="accordion-trigger-06" class="accordiontrigger" aria-controls="accordion-content-06" aria-hidden="true">
										What happens if I don&rsquo;t enroll by the deadline?
									</button>
									<div id="accordion-content-06" class="accordion-content" aria-labelledby="accordion-trigger-06">
										<div class="contentwrap">
											<p>If you do not enroll when you become eligible, you may receive penalties.</p>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<button type="button" id="accordion-trigger-07" class="accordiontrigger" aria-controls="accordion-content-07" aria-hidden="true">
										How do I enroll in Medicare?
									</button>
									<div id="accordion-content-07" class="accordion-content" aria-labelledby="accordion-trigger-07">
										<div class="contentwrap">
											<p>You can sign up for Medicare Part A and Part B through the  <a href="https://www.ssa.gov" class="external">Social Security Administration</a>. If you would like to enroll in a Medicare Advantage plan, contact the provider of the plan(s) that interests you.</p>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<button type="button" id="accordion-trigger-08" class="accordiontrigger" aria-controls="accordion-content-08" aria-hidden="true">
										What if I plan on working past the age of 65 and want to continue coverage through my employer?
									</button>
									<div id="accordion-content-08" class="accordion-content" aria-labelledby="accordion-trigger-08">
										<div class="contentwrap">
											<p>Enroll in Part A. It is available at no cost. You will not need to enroll in Part B at this time if the employer has more than 20 employees. You should ask your employer about health plans available to you.</p>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<button type="button" id="accordion-trigger-09" class="accordiontrigger" aria-controls="accordion-content-09" aria-hidden="true">
										What if I choose to keep the plan I enrolled in on the NY State or VT Marketplace?
									</button>
									<div id="accordion-content-09" class="accordion-content" aria-labelledby="accordion-trigger-09">
										<div class="contentwrap">
											<p>You can choose to keep that plan but once you turn 65, you will lose any premium tax credits or other savings you currently receive.</p>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<button type="button" id="accordion-trigger-10" class="accordiontrigger" aria-controls="accordion-content-10" aria-hidden="true">
										Can I buy a Medicare plan through the NY State or VT Marketplace?
									</button>
									<div id="accordion-content-10" class="accordion-content" aria-labelledby="accordion-trigger-10">
										<div class="contentwrap">
											<p>No. Medicare is not offered on Federal or State Exchange/Marketplaces.</p>
										</div>
									</div>
								</div>				
							</div>
							
							<h4>Open Enrollment</h4>
							<div class="accordion">
								<div class="accordion-item">
									<button type="button" id="accordion-trigger-11" class="accordiontrigger" aria-controls="accordion-content-11" aria-hidden="true">
										Can I switch Medicare Advantage plans?
									</button>
									<div id="accordion-content-11" class="accordion-content" aria-labelledby="accordion-trigger-11">
										<div class="contentwrap">
											<p>Yes. Every year, there is an Open Enrollment period when you can switch Medicare plans for the coming year. Open Enrollment is October 15 through December 7.</p>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<button type="button" id="accordion-trigger-12" class="accordiontrigger" aria-controls="accordion-content-12" aria-hidden="true">
										What is Open Enrollment?
									</button>
									<div id="accordion-content-12" class="accordion-content" aria-labelledby="accordion-trigger-12">
										<div class="contentwrap">
											<p>Open Enrollment is the period of time in which Medicare beneficiaries may change their Medicare coverage. It is the one period during the year, unless there is a qualifying event, when a change can be made.</p>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<button type="button" id="accordion-trigger-13" class="accordiontrigger" aria-controls="accordion-content-13" aria-hidden="true">
										Can I change my Medicare plan at times other than the Open Enrollment period?
									</button>
									<div id="accordion-content-13" class="accordion-content" aria-labelledby="accordion-trigger-13">
										<div class="contentwrap">
											<p>You can change or switch your Medicare Advantage plan or Part D plan at other times if certain qualifying events have happened in your life, such as moving to a new area or losing your current coverage. Typically these Special Enrollment Periods last two months from the time of the event, but the rules and timeframes can be different, depending on your circumstance. <a href="https://www.medicare.gov/sign-up-change-plans/when-can-i-join-a-health-or-drug-plan/special-circumstances-special-enrollment-periods" className="external">Get more details</a>.</p>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<button type="button" id="accordion-trigger-14" class="accordiontrigger" aria-controls="accordion-content-14" aria-hidden="true">
										If I&rsquo;m happy with my current Medicare plan, do I need to do anything during Open Enrollment?
									</button>
									<div id="accordion-content-14" class="accordion-content" aria-labelledby="accordion-trigger-14">
										<div class="contentwrap">
											<p>No. If you are happy with your current plan you do not have to take any actions. However, new premiums or changes in your plan can occur, so it&rsquo;s important to review the new information as soon as it is available to you.</p>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<button type="button" id="accordion-trigger-15" class="accordiontrigger" aria-controls="accordion-content-15" aria-hidden="true">
										I&rsquo;m 72 and plan on retiring in six months. Do I have to wait until Open Enrollment to enroll in Medicare?
									</button>
									<div id="accordion-content-15" class="accordion-content" aria-labelledby="accordion-trigger-15">
										<div class="contentwrap">
											<p>No. Retiring from your job is considered a qualifying event which makes you eligible for a Special Enrollment period. You have up to two months after the &ldquo;event&rdquo; to enroll in Medicare. So it makes sense to prepare in advance by comparing plans before you retire.</p>
										</div>
									</div>
								</div>						
							</div>
							
							<h4>Medicare Advantage Plans</h4>
							<div class="accordion">
								<div class="accordion-item">
									<button type="button" id="accordion-trigger-16" class="accordiontrigger" aria-controls="accordion-content-16" aria-hidden="true">
										Where can I learn more about UVM Health Advantage Medicare Advantage plans?
									</button>
									<div id="accordion-content-16" class="accordion-content" aria-labelledby="accordion-trigger-16">
										<div class="contentwrap">
											<p>You can compare UVM Health Advantage Medicare plans online. If you prefer to speak to someone in person you can call a a UVM Health Advantage Plan Guide at 1-833-368-4592 or schedule an appointment.</p>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<button type="button" id="accordion-trigger-17" class="accordiontrigger" aria-controls="accordion-content-17" aria-hidden="true">
										How do I know which Medicare plan combination is best for me?
									</button>
									<div id="accordion-content-17" class="accordion-content" aria-labelledby="accordion-trigger-17">
										<div class="contentwrap">
											<p>Everyone&rsquo;s situation is different so it&rsquo;s important to compare plans and their TOTAL costs (copays, prescriptions, etc.), not just premiums. We recommend contacting an a UVM Health Advantage Plan Guide or attending a seminar.</p>
											<p>Get a <a href="https://bestplanforme.mvphealthcare.com/?sourcesite=uvm">personalized plan recommendation</a>.</p>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<button type="button" id="accordion-trigger-18" class="accordiontrigger" aria-controls="accordion-content-18" aria-hidden="true">
										Will I lose my Medicare Part and Part B coverage if I join a UVM Health Advantage Plan?
									</button>
									<div id="accordion-content-18" class="accordion-content" aria-labelledby="accordion-trigger-18">
										<div class="contentwrap">
											<p>No. Your UVM Health Advantage Plan will provide your Medicare benefits to you. You will still need to maintain your Part A and Part B Medicare enrollment.</p>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<button type="button" id="accordion-trigger-19" class="accordiontrigger" aria-controls="accordion-content-19" aria-hidden="true">
										What can I expect to pay for a UVM Health Advantage Plan?
									</button>
									<div id="accordion-content-19" class="accordion-content" aria-labelledby="accordion-trigger-19">
										<div class="contentwrap">
											<p>UVM Health Advantage plans offer a variety of affordable premiums. But it&rsquo;s important to look at your total costs, which include deductibles, copays, and coinsurance.</p>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<button type="button" id="accordion-trigger-20" class="accordiontrigger" aria-controls="accordion-content-20" aria-hidden="true">
										Who accepts UVM Health Advantage health insurance?
									</button>
									<div id="accordion-content-20" class="accordion-content" aria-labelledby="accordion-trigger-20">
										<div class="contentwrap">
											<p>UVM Health Advantage members have access to MVP&rsquo;s network of over 23,000 doctors and hospitals in New York and Vermont &ndash; including access to all the providers of the UVM Health Network</p>
										</div>
									</div>
								</div>
								
							</div>

							<LearnMore />
							<SpeakWithAdvisor />
						</main>

						<SideBar location={location} />
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default MBFAQ